.comments {
  .comment {
    margin: 0 0 12px 0;

    .comment-member-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 16px;
    }

    .comment-member-name {
      font-size: 14px;
      font-weight: 600;
    }

    .comment-time {
      font-size: 12px;
    }

    .comment-bubble {
      position: relative;
      padding: 6px;
      border-radius: 0 20px 20px 20px;
      background-color: #f5f5f5;
      border: 1px solid #f5f5f5;
    }

    &.new-comment {
      margin: 0 0 24px 0;

      mat-form-field {
        .mat-form-field-wrapper {
          padding: 0;
        }
      }
    }
  }
}
